import Vue from 'vue'
import VueRouter from 'vue-router'


// 路由懒加载
const Login = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '../components/Login.vue')
const Home = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '../components/Home.vue')
const Welcome = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '../components/Dashboard/index.vue')

const GoodsList = () => import(/* webpackChunkName: "GoodsList_Add" */ '../components/shopManage/goods/List.vue')
const Add = () => import(/* webpackChunkName: "GoodsList_Add" */ '../components/shopManage/goods/Add.vue')
const ShopOrderList = () => import(/* webpackChunkName: "GoodsList_Add" */ '../components/shopManage/order/ShopOrder.vue')
/**商城模块-end */


/** 农特产 end  */

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: Login },
  { path: '/home',
    component: Home,
    redirect: '/Welcome',
    children: [
      { path: '/home', component: Welcome },
      { path: '/goods', component: GoodsList },
      { path: '/goods/add', component: Add },
      { path: '/orders_shop', component: ShopOrderList },
    ] }
]

const router = new VueRouter({
  routes
})

// 挂载路由导航守卫,to表示将要访问的路径，from表示从哪里来，next是下一个要做的操作 next('/login')强制跳转login
router.beforeEach((to, from, next) => {
  // 访问登录页，放行
  if (to.path === '/login') return next()
  // 获取token
  const tokenStr = window.sessionStorage.getItem('token')
  // 没有token, 强制跳转到登录页
  if (!tokenStr) return next('/login')
  next()
})

export default router
